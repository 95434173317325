import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeTwoComponent } from './components/pages/home-two/home-two.component';
import { AboutComponent } from './components/pages/about/about.component';
import { NewsComponent } from './components/pages/news/news.component';
import { NewsDetailsComponent } from './components/pages/news-details/news-details.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { TermsConditionsComponent } from './components/pages/terms-conditions/terms-conditions.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { OnlineMarketingComponent } from './temp/digital-marketing/online-marketing/online-marketing.component';
import { SEOComponent } from './temp/digital-marketing/seo/seo.component';
import { SocialMediaComponent } from './temp/digital-marketing/social-media/social-media.component';

const routes: Routes = [
    {path: '', component: HomeTwoComponent},
    {path: 'about', component: AboutComponent},
    {path: 'news', component: NewsComponent},
    {path: 'news-details/:key', component: NewsDetailsComponent},
    {path: 'error', component: ErrorComponent},
    {path: 'faq', component: FaqComponent},
    {path: 'terms-condition', component: TermsConditionsComponent},
    {path: 'privacy-policy', component: PrivacyPolicyComponent},
    {path: 'contact', component: ContactComponent},

    // Temp
    {path: 'online-marketing', component: OnlineMarketingComponent},
    {path: 'seo', component: SEOComponent},
    {path: 'social-media', component: SocialMediaComponent},

    // Just to work the redirection from the old website
    {path: '**', redirectTo: '/'} // This line will remain down from the whole pages component list

];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        scrollPositionRestoration: 'enabled',
       anchorScrolling: 'enabled',
       scrollOffset: [0, 120]
    })],
    exports: [RouterModule] 
})
export class AppRoutingModule { }