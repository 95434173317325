import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-faq',
    templateUrl: './faq.component.html',
    styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {}

    accordionItems = [
        {
            title: 'Why Optimum?',
            content: `With over 20 years of experience of developing and implementing ERP solutions for multiple industries, Optimum is your obvious choice. With its unique features of multi-company, multi-currency, inbuilt workflow and multi-language interface Optimum is tailored to your requirements.`,
            open: false
        },
        {
            title: 'Why should I list my products on Mallats Marketplace?',
            content: `Mallats Marketplace is the only marketplace which provides you with your own e-shop, integrated POS solution, Backoffice accounting solution and mobile app. Listing with Mallats saves cost and time, which you would have to spend on different systems to meet your requirements. It’s an All-in-One system for all your needs.`,
            open: false
        },
        {
            title: 'What are the benefits of cloud solutions (SaaS) with Al-Amthal?',
            content: `Our SaaS solutions fit into any type of industry with ease. Unique advantages include flexible and cost-effective subscription, easy to use interface, multi-company, multi-currency and multi-lingual interface. You may automate your complete business with our SaaS solutions.`,
            open: false
        },
        {
            title: 'Why should I trust Al-Amthal for my FinTech requirements?',
            content: `Al-Amthal boasts 20+ years of experience of working with various financial institutions. Our FinTech products are not only developed using the latest technology, but also provide a unique all-in-one solution for your financial institution including all back office and front office operations and transactions.`,
            open: false
        }
    ];

    selectedItem : any = null;

    toggleAccordionItem(item:any) {
        item.open = !item.open;
        if (this.selectedItem && this.selectedItem !== item) {
            this.selectedItem.open = false;
        }
        this.selectedItem = item;
    }

}