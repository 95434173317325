import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { NgxScrollTopModule } from 'ngx-scrolltop';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideHttpClient, withInterceptorsFromDi, withJsonpSupport } from  '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/common/navbar/navbar.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { HomeTwoComponent } from './components/pages/home-two/home-two.component';
import { AboutComponent } from './components/pages/about/about.component';
import { NewsComponent } from './components/pages/news/news.component';
import { NewsDetailsComponent } from './components/pages/news-details/news-details.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { TermsConditionsComponent } from './components/pages/terms-conditions/terms-conditions.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { CommonModule } from '@angular/common';
import { NgxPaginationModule } from 'ngx-pagination';

import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient} from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from "ng-recaptcha";
import { environment } from 'src/environments/environment';

import { FormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';

import { OnlineMarketingComponent } from './temp/digital-marketing/online-marketing/online-marketing.component';
import { OtpModalComponent } from './components/otp-modal/otp-modal.component';
import { SEOComponent } from './temp/digital-marketing/seo/seo.component';
import { SocialMediaComponent } from './temp/digital-marketing/social-media/social-media.component';
import { ImageModalComponent } from './components/image-modal/image-modal.component';
import { LoaderComponent } from './components/loader/loader.component';
import { PdfViewerModalComponent } from './components/pdf-viewer-modal/pdf-viewer-modal.component';

import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PdfViewerOptionsModalComponent } from './components/pdf-viewer-options-modal/pdf-viewer-options-modal.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { AnnouncementComponent } from './components/announcement/announcement.component';
import { authInterceptorProviders } from './helpers/auth.interceptor';

@NgModule({ declarations: [
        AppComponent,
        NavbarComponent,
        FooterComponent,
        HomeTwoComponent,
        AboutComponent,
        NewsComponent,
        NewsDetailsComponent,
        ErrorComponent,
        FaqComponent,
        TermsConditionsComponent,
        PrivacyPolicyComponent,
        ContactComponent,
        OnlineMarketingComponent,
        OtpModalComponent,
        SEOComponent,
        SocialMediaComponent,
        ImageModalComponent,
        LoaderComponent,
        PdfViewerModalComponent,
        PdfViewerOptionsModalComponent,
        AnnouncementComponent
    ],
    exports: [
        HomeTwoComponent
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA], imports: [FormsModule,
        ToastrModule.forRoot(),
        BrowserModule,
        AppRoutingModule,
        RecaptchaV3Module,
        CarouselModule,
        BrowserAnimationsModule,
        NgxScrollTopModule,
        ReactiveFormsModule,
        CommonModule,
        GoogleMapsModule,
        NgxPaginationModule,
        // PdfViewerModule,
        // NgxExtendedPdfViewerModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })], providers: [
        authInterceptorProviders,
        {
            provide: RECAPTCHA_V3_SITE_KEY,
            useValue: environment.recaptcha.siteKey
        },
        NgbActiveModal,
        provideClientHydration(),
        provideHttpClient(withInterceptorsFromDi(), withJsonpSupport())
    ] })
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}