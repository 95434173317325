<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>{{ 'Social Media' | translate }}</h2>
            <ul>
                <li><a routerLink="/">{{ 'Home' | translate }} </a></li>
                <li class="active">{{ 'Social Media' | translate }}</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img loading="lazy" src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img loading="lazy" src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img loading="lazy" src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img loading="lazy" src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img loading="lazy" src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="feature-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12">
                <p>{{'Social media marketing is a powerful way for businesses of all sizes to reach prospects and customers. Your customers are already interacting with brands through social media, and if you’re not speaking directly to your audience through social platforms like Facebook, Twitter, Instagram, and Pinterest, you’re missing out! Great marketing on social media can bring remarkable success to your business, creating devoted brand advocates and even driving leads and sales.' | translate }}</p>
                <p>{{ 'Al-Amthal provides with best social media marketing through their Facebook and Instagram pages which have substantial following. These services are divided into three main categories:' | translate }}</p>
            </div>

            <div class="col-lg-6 mt-4">
                <div class="feature-img">
                    <img loading="lazy" class="rounded" src="https://system.al-amthal.com/AmthalV12/Upload/B2C/FaceBook.jpg" alt="feature" />
                </div>
            </div>

            <div class="col-lg-6 mt-4">
                <div class="contnet">
                    <div class="feature-tittle">
                        <h2>{{'Facebook' | translate}}</h2>
                        <p>{{'Through a well experienced and qualified team, Al-Amthal provides its valuable customers with attractive posts on Facebook including designing. The posts can either be posted directly on the business page or on one of Al-Amthal’s pages like Mallats or Aqaraats. Paid campaigns can also be created and managed.' | translate}}</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 mt-4">
                <div class="contnet">
                    <div class="feature-tittle">
                        <h2>{{'Instagram' | translate}}</h2>
                        <p>{{'Instagram ads and posts have become most popular and affluent marketing tools lately. Through a well experienced and qualified team, Al-Amthal provides its valuable customers with attractive posts on Instagram including designing. The posts can either be posted directly on the business page or on one of Al-Amthal’s pages like Mallats or Aqaraats. Paid campaigns can also be created and managed.' | translate}}</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 mt-4">
                <div class="feature-img">
                    <img loading="lazy" class="rounded" src="https://system.al-amthal.com/AmthalV12/Upload/B2C/instagram.jpg" alt="feature" />
                </div>
            </div>

            <div class="col-lg-6 mt-4">
                <div class="feature-img">
                    <img loading="lazy" class="rounded" src="https://system.al-amthal.com/AmthalV12/Upload/B2C/YouTube.jpg" alt="feature" />
                </div>
            </div>

            <div class="col-lg-6 mt-4">
                <div class="contnet">
                    <div class="feature-tittle">
                        <h2>{{'YouTube' | translate}}</h2>
                        <p>{{ 'YouTube ads are extremely polpular among young population with great influence on their spending behaviour. Through a well experienced and qualified team, Al-Amthal provides its valuable customers with attractive videos for YouTube. The posts can either be posted directly on the business handle or on one of Al-Amthal’s handles like Mallats or Aqaraats. Paid campaigns can also be created and managed.' | translate }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>