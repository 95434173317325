import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { TextService } from 'src/app/services/text.service';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {

  newsData: any = null;
  page: number = 1;
  executedRender: boolean = false;

  constructor(private http: HttpClient,
    public textService: TextService,
      private meta: Meta,
      private _translate: TranslateService,
      private title: Title,
      @Inject(PLATFORM_ID) private platformId:Object
    ) {
      this.platformId = platformId;

      this._translate.get('Stay informed and connected with our engaging newsletter.').subscribe({
        next: (res: string) => {
          this.meta.updateTag({name: 'description', content: res});
        }
      });

      this._translate.get('All News').subscribe({
        next: (res: string) => {
          this.title.setTitle(res);
        }
    });
  }

  ngOnInit(): void {
    if(isPlatformBrowser(this.platformId)){
      this.http.get('https://system.al-amthal.com/optimumapiv3/Noticeboard/CMSData?menuID=61&pageID=70&compID=16').subscribe({
        next: (val: any) => {
            this.newsData = val;
        }
      });
    }
  }

  pageChanged(event: any) {
    this.page = event;

    // Find the element you want to scroll to by its ID
    const elementToScrollTo = document.querySelector('.home-blog-area');

    // Check if the element exists before scrolling
    if (elementToScrollTo) {
      // Scroll to the element
      elementToScrollTo.scrollIntoView({ behavior: 'smooth' }); // Add smooth scrolling behavior
    }
  }

  public getBackgroundImageUrl(newsValue: string): string {
    const encodedValue = this.textService.encode(newsValue).replace(/\(/g, '\\(').replace(/\)/g, '\\)');
    return `url(https://system.al-amthal.com/amthalv12/${encodedValue})`;
  }

}
