<div class="content">
    <div class="modal-header">
        <h5 class="modal-title">{{ 'OTP Verification' | translate}}</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="closePopUp(false)"></button>
    </div>
    <div class="modal-body">
        <p>{{'OTP has been sent to your email. Please input OTP to submit the request. Our Sales Team will contact you soon.' | translate}}</p>
        <div class="col-12 mb-3" id="contact-purchase-otp-error" *ngIf="isSubmitted && responseMessage != ''" [ngClass]="{'success': !isFailed, 'error': isFailed}">
            <div class="alert alert-danger mb-0 mt-3" role="alert">{{responseMessage | translate}}</div>
        </div>
            <input 
                type="text" 
                [placeholder]="'OTP Verification Code' | translate"
                required
                [ngClass]="{'border-error' : isSubmitted && isFailed}"
                [(ngModel)]="otpEntered"
                />
    </div>
    <div class="modal-footer">
        <button type="button"  class="btn btn-primary" (click)="submit()">
            <span  *ngIf="!isLoading">{{'Submit' | translate}}</span>
            <div class="loader"  *ngIf="isLoading"></div>
            
        </button>
    </div>
</div>