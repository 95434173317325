import { AfterViewInit, Component, ElementRef, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { HttpClient } from  '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { TextService } from 'src/app/services/text.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ImageModalComponent } from '../../image-modal/image-modal.component';
import { NgbModal, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { Meta,Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { PdfViewerModalComponent } from '../../pdf-viewer-modal/pdf-viewer-modal.component';
import { isPlatformBrowser, Location } from '@angular/common';
import { TrackerService } from 'src/app/services/tracker.service';
import { PdfViewerOptionsModalComponent } from '../../pdf-viewer-options-modal/pdf-viewer-options-modal.component';
import { AnnouncementComponent } from '../../announcement/announcement.component';
import { SessionService } from 'src/app/services/session.service';

@Component({
    selector: 'app-home-two',
    templateUrl: './home-two.component.html',
    styleUrls: ['./home-two.component.scss']
})
export class HomeTwoComponent implements OnInit, AfterViewInit {
    public certificationPartners: any = [
        
    ];

    public partnerPartners: any = [
        {
            badgeName: 'Partner',
            logo: 'https://cdn2.mallats.com/AmthalGroup/img/AmthalClients/afs-logo.png',
            header: 'Payment Gateway'
        },
        {
            badgeName: 'Partner',
            logo: 'https://cdn2.mallats.com/AmthalGroup/img/pc/ahli-united-bank.png',
            header: 'Financial Integration'
        },
        {
            badgeName: 'Partner',
            logo: 'https://cdn2.mallats.com/AmthalGroup/img/AmthalClients/credimax-logo.png',
            header: 'Payment Gateway'
        },
        {
            badgeName: 'Partner',
            logo: 'https://cdn2.mallats.com/AmthalGroup/img/pc/microsoft-gold.png',
            header: 'Information Technology',
            link: 'https://www.amthalgroup.com/docs/MicrosoftPartner.pdf',
            type: 'pdf'
        },
        {
            badgeName: 'Partner',
            logo: 'https://cdn2.mallats.com/AmthalGroup/img/pc/zkteco.png',
            header: 'Time Attendance Integration'
        }
    ];

    public teams: any = [
        {
            profile: 'https://cdn2.mallats.com/AmthalGroup/img/team/Hani.jpg',
            name: 'Hani Awachi',
            position: 'Chief Executive'
            
        },
        {
            profile: 'https://cdn2.mallats.com/AmthalGroup/img/team/Zahra.jpg',
            name: 'Beate Schoek',
            position: 'General Manager'
            
        },
        {
            profile: 'https://cdn2.mallats.com/AmthalGroup/img/team/Nixon.jpg',
            name: 'Nixon Rebeiro',
            position: 'Branch Manager'
            
        },
        {
            profile: 'https://cdn2.mallats.com/AmthalGroup/img/team/Vinod.jpg',
            name: 'Vinod Nair',
            position: 'Head of Development'
            
        },
        {
            profile: 'https://cdn2.mallats.com/AmthalGroup/img/team/Vimal.jpg',
            name: 'Vimal Govind',
            position: 'Head of Software Support'
            
        },
        {
            profile: 'https://cdn2.mallats.com/AmthalGroup/img/team/Ahmed.jpg?v=5',
            name: 'Ahmed Awachi',
            position: 'Head of Frontend Development'
            
        },
        {
            profile: 'https://cdn2.mallats.com/AmthalGroup/img/team/Irfan.jpg',
            name: 'Irfan Pasha',
            position: 'Head of Implementation'
            
        },
        {
            profile: 'https://cdn2.mallats.com/AmthalGroup/img/team/Sayed.jpg',
            name: 'Sayed Ahmed Salman',
            position: 'Head of Net. & Comm.'
            
        },
        {
            profile: 'https://cdn2.mallats.com/AmthalGroup/img/team/Shelain.jpg',
            name: 'Shelain Brown',
            position: 'Digital Marketing Executive'
            
        },

        {
            profile: 'https://cdn2.mallats.com/AmthalGroup/img/team/AhmedSalih.jpg?1',
            name: 'Ahmad Abdullah Salih',
            position: 'Head of Operations'
            
        },
        {
            profile: 'https://cdn2.mallats.com/AmthalGroup/img/team/Shaima.jpg',
            name: 'Shaima Farhan Alshamari',
            position: 'Head of Business Development'
            
        }
        
    ]

    public clients: any = [
        {
            url: 'https://gigtakaful.bh/',
            img: 'https://cdn2.mallats.com/AmthalGroup/img/AmthalClients/gig-takaful.png',
            name: ''
        },
        {
            url: 'https://www.albadigroup.com/en/',
            img: 'https://cdn2.mallats.com/AmthalGroup/img/AmthalClients/al-badi-group.png',
            name: ''
        },
        {
            url: 'https://www.shura.bh/ar/pages/default.aspx',
            img: 'https://cdn2.mallats.com/AmthalGroup/img/AmthalClients/Shura.png',
            name: ''
        },
        {
            url: 'https://www.nbk.com/bahrain',
            img: 'https://cdn2.mallats.com/AmthalGroup/img/AmthalClients/NBK.png',
            name: ''
        },
        {
            url: 'http://www.crpep.bh/',
            img: 'https://cdn2.mallats.com/AmthalGroup/img/AmthalClients/CRPEP.png',
            name: ''
        },
        /* {
            url: 'https://www.lebanesebh.com/',
            img: 'https://cdn2.mallats.com/AmthalGroup/img/AmthalClients/Lebanese-Restaurant.png'
        }, */
        {
            url: 'https://asiajewellers.com/',
            img: 'https://cdn2.mallats.com/AmthalGroup/img/AmthalClients/Asia-Jewelers.png',
            name: ''
        },
        {
            url: 'https://www.alhelli.com/',
            img: 'https://cdn2.mallats.com/AmthalGroup/img/AmthalClients/AlHelli.png',
            name: ''
        },
        {
            url: 'https://www.st-chris.net/',
            img: 'https://cdn2.mallats.com/AmthalGroup/img/AmthalClients/St.ChristpherSchool.png',
            name: ''
        },
        {
            url: 'https://www.ahlia.edu.bh/',
            img: 'https://cdn2.mallats.com/AmthalGroup/img/AmthalClients/AhliaUniversity.png',
            name: ''
        },
        {
            url: 'https://www.moamalat.net/en/Home',
            img: 'https://cdn2.mallats.com/AmthalGroup/img/AmthalClients/Moamalat.png',
            name: ''
        },        
        /* {
            url: 'http://www.fakhro.com/Restaurants.asp',
            img: 'https://cdn2.mallats.com/AmthalGroup/img/AmthalClients/McDonalds.png'
        }, */
        {
            url: 'https://www.binalsheikh.com/',
            img: 'https://cdn2.mallats.com/AmthalGroup/img/AmthalClients/BinSheikh.png',
            name: ''
        },
        {
            url: 'https://mannaiholding.com/',
            img: 'https://cdn2.mallats.com/AmthalGroup/img/AmthalClients/MannaiProperties.png',
            name: ''
        },
        {
            url: 'https://aubh.edu.bh/',
            img: 'https://cdn2.mallats.com/AmthalGroup/img/AmthalClients/AmericanUniversityofBahrain.png',
            name: ''
        },
        {
            url: 'https://www.bahraingp.com/',
            img: 'https://cdn2.mallats.com/AmthalGroup/img/AmthalClients/BahrainInternationalCircuit.png',
            name: ''
        },
        {
            url: 'https://prclibya.com/',
            img: 'https://cdn2.mallats.com/AmthalGroup/img/AmthalClients/NationalOilCorporation.png',
            name: ''
        },
        {
            url: 'https://www.kfh.bh/bahrain/en/personal.html',
            img: 'https://cdn2.mallats.com/AmthalGroup/img/AmthalClients/kfh.png',
            name: ''
        },
        {
            url: 'https://www.credimax.com.bh/en/home',
            img: 'https://cdn2.mallats.com/AmthalGroup/img/AmthalClients/credimax-logo.png',
            name: ''
        },      
        {
            url: 'https://www.nuwab.bh/',
            img: 'https://cdn2.mallats.com/AmthalGroup/img/AmthalClients/Nuwab.png',
            name: ''
        },
        {
            url: 'https://www.bahrainbourse.com/',
            img: 'https://cdn2.mallats.com/AmthalGroup/img/AmthalClients/Bahrain-Bourse.png',
            name: ''
        },
        {
            url: 'https://www.msx.om/',
            img: 'https://cdn2.mallats.com/AmthalGroup/img/AmthalClients/MSX.png',
            name: ''
        },
        {
            url: 'https://www.afs.com.bh/',
            img: 'https://cdn2.mallats.com/AmthalGroup/img/AmthalClients/afs-logo.png',
            name: ''
        }  
    ]

    constructor(private http: HttpClient,
        public activatedRoute: ActivatedRoute,
        private router: Router,
        public textService: TextService,
        private modalService: NgbModal,
        private meta: Meta,
        private _translate: TranslateService,
        private title: Title,
        private Location: Location,
        public sessionService: SessionService,
        private tracker: TrackerService,
        private offcanvasModal: NgbOffcanvas,
        @Inject(PLATFORM_ID) private platformId:Object
    ) {
        this.platformId = platformId;
        
        this.meta.updateTag({name: 'description', content: 'Amthal Group is the leading cloud ERP solutions provider in the Kingdom of Bahrain and other GCC countries.'});

        this._translate.get('Amthal Group - Leading Business Solutions Provider in Middle East').subscribe({
            next: (res: string) => {
              this.title.setTitle(res);
            }
        });


        // this.router.events.subscribe((event:any) => {
        //     if ( event.url == '/?section=partners') {
        //         const yOffset = this.productsElement.nativeElement.getBoundingClientRect().top + window.pageYOffset;
        //         window.scrollTo({ top: yOffset, behavior: 'smooth' });              
        //     }
        // });
    }
    ngAfterViewInit() {
        //TODO: Get Data from the API
        let announcement: any = {
            id: '07/14/2024',
            startDate: '07/14/2024',
            endDate: '07/18/2024',
            img: 'assets/images/popups/Announcement_Amthal.jpg',
            title: '',
            subtitle: ''
        };
        
        /* if(this.sessionService.IsViewPopUp(announcement.id) && this.isWithInDate(announcement.startDate, announcement.endDate)) {
            setTimeout(() => {
                const AnnouncementModal = this.modalService.open(AnnouncementComponent, {size: 'md', scrollable: true, centered: true});
                AnnouncementModal.componentInstance.modalData = announcement;
                AnnouncementModal.componentInstance.closeModal.subscribe((result: any) => {
                  if(result.isChecked) {
                    this.sessionService.DontShowAnnoucement(result.id);
                  } else {
                    this.sessionService.ShowAnnoucement(result.id);
                  }
                });
            }, 1000);
        } */
  
    }
  



    ngOnInit(): void {
        if(isPlatformBrowser(this.platformId)){
            this.certificationPartners = [
                {
                    badgeName: 'Certification',
                    logo: 'https://cdn2.mallats.com/AmthalGroup/new/ISO-9001.png',
                    header: 'Quality Management System',
                    link: '/assets/docs/ISO-9001.pdf',
                    type: 'pdf'
                },
                {
                    badgeName: 'Certification',
                    logo: 'https://cdn2.mallats.com/AmthalGroup/new/ISO-27001.png',
                    header: 'Information Security System',
                    link: '/assets/docs/ISO-27001.pdf',
                    type: 'pdf'
                },
                {
                    badgeName: 'Certification',
                    logo: 'https://cdn2.mallats.com/AmthalGroup/img/pc/pci.png',
                    header: 'Payment Gateway Security'
                },
                /* {
                    badgeName: 'Certification',
                    logo: 'https://cdn2.mallats.com/AmthalGroup/img/pc/windows-server-2012.png',
                    header: 'Software Compatibility'
                } */
            ];
    
            this.homeSlides = {
                /* animateOut: 'slideOutDown',
                animateIn: 'slideInDown', */
                items: 1,
                loop: true,
                autoplay: true,
                dots: false,
                nav: true,
                //autoHeight: true,
                autoplaySpeed: 800,
                mouseDrag: false,
                autoplayHoverPause: false,
                navText: [
                    localStorage.getItem('language') == 'ar' ? "<i class='flaticon-next-1'></i>" : "<i class='flaticon-left-arrow'></i>", 
                    localStorage.getItem('language') == 'ar' ? "<i class='flaticon-left-arrow'></i>" : "<i class='flaticon-next-1'></i>"
                ],
                rtl:localStorage.getItem('language') == 'ar',
                responsive: {
                    0: {
                        items: 1,
                    },
                    576: {
                        items: 1,
                    },
                    768: {
                        items: 1,
                    },
                    1200: {
                        items: 1,
                    }
                }
            };
            
            this.teamSlides = {
                loop: true,
                margin: 20,
                dots: true,
                autoplay: true,
                autoplayHoverPause: true,
                rtl:localStorage.getItem('language') == 'ar',
                responsive: {
                    0: {
                        items: 1,
                    },
                    575: {
                        items: 2,
                    },
                    576: {
                        items: 2,
                    },
                    768: {
                        items: 3,
                    },
                    992: {
                        items: 3,
                    },
                    1200: {
                        items: 4,
                    }
                }
            };
    
            this.clientsSlider = {
                dots: false,
                loop: true,
                //margin: 50,
                //center: true,       
                autoplay: true,
                //autoplayHoverPause: true,
                stagePadding: 20,
                nav: true,
                rtl:localStorage.getItem('language') == 'ar',
                navText: [
                    localStorage.getItem('language') == 'ar' ? "<i class='flaticon-next-1'></i>" : "<i class='flaticon-left-arrow'></i>", 
                    localStorage.getItem('language') == 'ar' ? "<i class='flaticon-left-arrow'></i>" : "<i class='flaticon-next-1'></i>"
                ],
                responsive: {
                    0: {
                        margin: 30,
                        items: 3,
                    },
                    575: {
                        margin: 30,
                        items: 4,
                    },
                    576: {
                        margin: 40,
                        items: 5,
                    },
                    768: {
                        items: 7,
                        margin: 40
                    },
                    992: {
                        items: 8,
                        margin: 50
                    },
                    1200: {
                        items: 9,                
                        margin: 50
                    }
                }
            };
    
            this.clientWrap = {
                loop:true,
                margin:30,
                nav:false,
                mouseDrag: true,
                items:1,
                dots: false,
                autoHeight: true,
                autoplay: true,
                smartSpeed: 800,
                autoplayHoverPause: true,
                center: false,
                rtl:localStorage.getItem('language') == 'ar',
                responsive:{
                    0:{
                        items:1,
                        margin: 10,
                    },
                    576:{
                        items:1,
                    },
                    768:{
                        items:2,
                        margin: 20,
                    },
                    992:{
                        items:2,
                    },
                    1200:{
                        items:2,
                    }
                }
            };
            
            this.divisions = [{
                id: 1,
                title:'E-Commerce Solutions',
                img: 'assets/images/divisions/MarketplaceGlobal_Logo.svg',
                description:'List your PRODUCTS & SERVICES online on one of our marketplaces based on your industry.',
                url: 'https://www.mallatsolutions.com/' + (localStorage.getItem('language') == 'en' || !localStorage.getItem('language')  ? 'en' : 'ar')
              },
              {
                  id: 2,
                  title:'Cloud Solutions',
                  img: 'assets/images/divisions/Wolke_Logo.svg',
                  description:'Manage your business on Cloud with a best-fit solution for your business.',
                  url: 'https://www.erpwolke.com/' + (localStorage.getItem('language') == 'en' || !localStorage.getItem('language')  ? 'en' : 'ar')
                },
                {
                  id: 3,
                  title:'On-Premise Applications',
                  img: 'assets/images/divisions/OptimumERP_Logo.svg',
                  description:'Licensed Functional Products (to automate specific function within the organization) or total solution (ERP).',
                  url: 'https://www.erpoptimum.com/' + (localStorage.getItem('language') == 'en' || !localStorage.getItem('language')  ? 'en' : 'ar')
                },
                {
                  id: 4,
                  title:'FinTech Applications',
                  img: 'assets/images/divisions/OptimumFintech_Logo.png',
                  description:'Leading FinTech hub for financial products whether On-premise (Licensed) or on Cloud (SaaS).',
                  url: 'https://fintech.erpoptimum.com/' + (localStorage.getItem('language') == 'en' || !localStorage.getItem('language')  ? 'en' : 'ar')
              },];
    
            this.productTabs = [{
                id: '1',
                title: 'E-Commerce Solutions',
                products: [{
                    id: 1,
                    title: localStorage.getItem('language') == 'ar' ? 'مولات (لقطاع الجملة والتجزئة)' : 'Mallats for Retail & Wholesale',
                    description: 'Mallats to retail customers combines all the variety of visiting a normal shopping mall in the virtual space with attractive special offers all with the convenience of shopping from home with the Mallats App.',
                    img: 'https://cdn2.mallats.com/AmthalGroup/img/e-commerce-solutions/Mallats.jpg',
                    link: 'https://www.mallatsolutions.com/en/ECommerce/Mallats',
                    pdf: 'https://system.al-amthal.com/AmthalV12/Upload/contact/2021-Mallats-Summary' + (localStorage.getItem('language') == 'en' || !localStorage.getItem('language') ? '-EN' : '-AR') + '.pdf',
                    youtube: 'https://www.youtube.com/watch?v=nDirclg2fE4',
                    website: 'https://www.mallats.com/home'
                },
                {
                    id: 2,
                    title: localStorage.getItem('language') == 'ar' ? 'عقارات (لقطاع العقارات)' : 'Aqaraats for Real Estate',
                    description: 'Real Estate and Property Management is being revolutionized with AQARAATS, a new and innovative online real estate e-commerce solution and, at the same time, much more.',
                    img: 'https://cdn2.mallats.com/AmthalGroup/img/e-commerce-solutions/Aqaraats.jpg',
                    link: 'https://www.mallatsolutions.com/en/ECommerce/Aqaraats',
                    pdf: 'https://system.al-amthal.com/AmthalV12/Upload/B2C/AQRBooklet' + (localStorage.getItem('language') == 'en' || !localStorage.getItem('language') ? 'EN' : 'AR') + '.pdf',
                    youtube: 'https://www.youtube.com/watch?v=8En6QRuxiQU',
                    website: 'https://www.aqaraats.com/'
                },
                {
                    id: 3,
                    title: localStorage.getItem('language') == 'ar' ? 'قريبــًا – مطاعم (لقطاع الأطعمة والمشروبات)' : 'Mtaem for F&B - COMING SOON',
                    description: 'Mtaem will provide the merchants and the customers with many services such as online purchase, table booking, sales orders, offers, App and much more.',
                    img: 'https://cdn2.mallats.com/AmthalGroup/img/e-commerce-solutions/Mtaem.jpg',
                    link: '',
                    disabled: true
                },
                {
                    id: 4,
                    title: localStorage.getItem('language') == 'ar' ? 'قريبــًا – خدمات (للقطاع الخدمى)' : 'Khidamat for Services - COMING SOON',
                    description: 'Khidamat will provide the services companies and the customers with many services such as online request, appointment booking, sales orders, offers, App and much more.',
                    img: 'https://cdn2.mallats.com/AmthalGroup/img/e-commerce-solutions/Khidamat.jpg',
                    link: '',
                    disabled: true
                },
                {
                    id: 5,
                    title: localStorage.getItem('language') == 'ar' ? 'قريبــًا – اشتراكات (لقطاع النوادى والجمعيات)' : 'Ishtirakat for Memberships - COMING SOON',
                    description: 'Ishtirakat will provide the services companies and the customers with many services such as online request, booking, sales orders, offers, App and much more.',
                    img: 'https://cdn2.mallats.com/AmthalGroup/img/e-commerce-solutions/Ishtirakat.jpg',
                    link: '',
                    disabled: true
                },
                {
                    id: 6,
                    title: localStorage.getItem('language') == 'ar' ? 'قريبــًا – حجوزات (للقطاع الترفيهي)' : 'Hojozat for Booking and Schedule - COMING SOON',
                    description: 'Hojozat will provide the reservation companies and the customers with many services such as online reservation, booking, sales orders, offers, App and much more.',
                    img: 'https://cdn2.mallats.com/AmthalGroup/img/e-commerce-solutions/hojozat.jpg',
                    link: '',
                    disabled: true
                }]
            },
            {
                id: '2',
                title: 'Cloud Solutions',
                products: [{
                    id: 7,
                    title: localStorage.getItem('language') == 'ar' ? 'نظام متكامل لأتمتة المؤسسات للبيع بالجملة والتجزئة' : 'Business Suite for Retail & Wholesale',
                    description: 'mytrading is a full-fledged cloud-based business software suite that covers full operations cycle of trading businesses including fully integrated modules for sales, purchase and inventory management. It is a multi-company, multi-branch and multi-location system.',
                    img: 'https://cdn2.mallats.com/AmthalGroup/img/Saas/1.jpg',
                    link: 'https://www.erpwolke.com/en/Wolke/mytrading',    
                },
                {
                    id: 8,
                    title: localStorage.getItem('language') == 'ar' ? 'نظام متكامل لأتمتة المؤسسات لخدمات والعقود': 'Business Suite for Services & Contracting',
                    description: 'myservices is a full-fledged cloud-based business software suite that covers full operations cycle of services & contracting businesses including fully integrated modules for services billing, contracting, procurement and inventory management.',
                    img: 'https://cdn2.mallats.com/AmthalGroup/img/Saas/2.jpg',
                    link: 'https://www.erpwolke.com/en/Wolke/myservices',    
                },
                {
                    id: 9,
                    title: localStorage.getItem('language') == 'ar' ? 'نظام متكامل لأتمتة مؤسسات لإدارة الممتلكات العقارية' : 'Business Suite for Property Management',
                    description: 'myrealestate is a full-fledged cloud-based business software suite that covers all aspects of real estate businesses. It is a multi-company, multi-branch and multi-location system. It can manage real-estate rental, sale and management.',
                    img: 'https://cdn2.mallats.com/AmthalGroup/img/Saas/3.jpg',
                    link: 'https://www.erpwolke.com/en/Wolke/myrealestate',    
                },
                {
                    id: 10,
                    title: localStorage.getItem('language') == 'ar' ? 'باقة محاسبية لكل قطاع' : 'Accounting Package for Every Industry',
                    description: 'myaccount is an activity-based cost accounting package (ABC Accounting), document-driven software, using the latest technology, covering the major requirements of small to medium organizations (SMES).',
                    img: 'https://cdn2.mallats.com/AmthalGroup/img/Saas/10.jpg',
                    link: 'https://www.erpwolke.com/en/Wolke/myaccount',       
                },
                {
                    id: 11,
                    title: localStorage.getItem('language') == 'ar' ? 'نظام متكامل لأتمتة المؤسسات الغير ربحية' : 'Business Suite for Non Profit Organizations',
                    description: 'mycharity is a full-fledged cloud-based business software suite that covers all aspects of charitable or non-profit organizations. It is a multi-company, multi-branch and multi-location system. It manages both donors and needy with proper back office accounting.',
                    img: 'https://cdn2.mallats.com/AmthalGroup/img/Saas/5.jpg',
                    link: 'https://www.erpwolke.com/en/Wolke/mycharity',     
                },
                {
                    id: 12,
                    title: localStorage.getItem('language') == 'ar' ? 'نظام متكامل لأتمتة المؤسسات لأعمال البناء' : 'Business Suite for Construction',
                    description: 'myconstruction is an all-in-one cloud-based next generation business suite software built for the construction industry.',
                    img: 'https://cdn2.mallats.com/AmthalGroup/img/Saas/9.jpg',
                    link: 'https://www.erpwolke.com/en/Wolke/myconstruction',      
                },
                {
                    id: 13,
                    title: localStorage.getItem('language') == 'ar' ? 'نظام متكامل لأتمتة المؤسسات التعليمية' : 'Business Suite for Educational Institutions',
                    description: 'myschool is an inclusive management system covering the whole needs of schools and school groups, right from the start without the need for complicated integrations. As standard, it is offered with robust completely managed cloud hosting, or as an option, on-premises installation.',
                    img: 'https://cdn2.mallats.com/AmthalGroup/img/Saas/4.jpg',
                    link: 'https://www.erpwolke.com/en/Wolke/myschool',    
                },
                {
                    id: 14,
                    title: localStorage.getItem('language') == 'ar' ? 'نظام متكامل لأتمتة المؤسسات للعضويات والحجوزات' : 'Business Suite for Memberships & Bookings',
                    description: 'myclub business suite is a fully-fledged membership management platform for clubs and associations that do it all. From people and website management to communication, content, financials, and support, the software gives admins complete control over an intuitively designed user experience (UX) with all the bells and whistles.',
                    img: 'https://cdn2.mallats.com/AmthalGroup/img/Saas/6.jpg',
                    link: 'https://www.erpwolke.com/en/Wolke/myclub',     
                },
                {
                    id: 15,
                    title: localStorage.getItem('language') == 'ar' ? 'نظام متكامل لأتمتة المؤسسات لقطاع الأطعمة والمشروبات' :'Business Suite for F & B Industry',
                    description: 'myrestaurant provides full stock (materials) control in real-time, sale via touchscreen tills, extensive tools and reports to control any F&B operation and much more.',
                    img: 'https://cdn2.mallats.com/AmthalGroup/img/Saas/7.jpg',
                    link: 'https://www.erpwolke.com/en/Wolke/myrestaurant',    
                }]
            },
            {
                id: '3',
                title: 'On-Premise Applications',
                products: [{
                    id: 16,
                    title: localStorage.getItem('language') == 'ar' ? 'نظام شامل لتجارة التجزئة والجملة' : 'Trading Management',
                    description: 'Optimum Trading Management software is a full-fledged e-ERP (Electronic Enterprise Resource Planning – Browser Based) solution that covers full operations cycle of trading businesses including fully integrated modules for sales, purchase and inventory management. It is a multi-company, multi-branch and multi-location system.',
                    img: 'https://cdn2.mallats.com/AmthalGroup/img/premise/1.jpg',
                    link: 'https://www.erpoptimum.com/en/Optimum/TradingManagement',
                    pdf: 'https://www.erpoptimum.com/docs/OptTrading.pdf',
                    youtube: 'https://www.youtube.com/watch?v=0W8tS3lNdDw',
                    website: ''    
                },
                {
                    id: 17,
                    title: localStorage.getItem('language') == 'ar' ? 'نظام شامل للخدمات والعقود' : 'Services & Contracting',
                    description: 'Optimum Services & Contracting software is a full-fledged e-ERP (Electronic Enterprise Resource Planning – Browser Based) solution that covers full operations cycle of services & contracting businesses including fully integrated modules for services & contracting, procurement and inventory management.',
                    img: 'https://cdn2.mallats.com/AmthalGroup/img/premise/2.jpg',
                    link: 'https://www.erpoptimum.com/en/Optimum/ServicesManagement',
                    pdf: 'https://www.erpoptimum.com/docs/OptServices.pdf',
                    youtube: 'https://www.youtube.com/watch?v=OeA7TPjIb_E',
                    website: ''      
                },
                {
                    id: 18,
                    title: localStorage.getItem('language') == 'ar' ? 'نظام شامل للعقارات' : 'Real Estate',
                    description: 'Optimum Real Estate is a full-fledged E-ERP (Electronic Enterprise Resource Planning - Total browser base) system that covers all aspects of hotels and real estate businesses. It is a multi-company, multi-branch and multi-location system. It can manage real-estate rental, sale and management.',
                    img: 'https://cdn2.mallats.com/AmthalGroup/img/premise/3.jpg',
                    link: 'https://www.erpoptimum.com/en/Optimum/RealEstate',
                    pdf: 'https://www.erpoptimum.com/docs/OptRealEstate.pdf',
                    youtube: 'https://www.youtube.com/watch?v=Ip9fCnJPtBI',
                    website: ''           
                },
                {
                    id: 19,
                    title: localStorage.getItem('language') == 'ar' ? 'نظام شامل للمؤسسات الغير ربحية' : 'Nonprofit Organizations ERP',
                    description: 'Optimum Nonprofit Organizations Software is a full-fledged E-ERP (Electronic Enterprise Resource Planning - Total browser base) system that covers all aspects of charitable or non-profit organizations. It is a multi-company, multi-branch and multi-location system. It manages both donors and needy with proper back office accounting.',
                    img: 'https://cdn2.mallats.com/AmthalGroup/img/premise/4.jpg',
                    link: 'https://www.erpoptimum.com/en/Optimum/NonProfitOrganization',
                    pdf: '',
                    youtube: 'https://www.youtube.com/watch?v=FPbC6t1jNLE',
                    website: ''    
                },
                {
                    id: 20,
                    title: localStorage.getItem('language') == 'ar' ? 'نظام شامل لإدارة المدارس' : 'Education (ERP)',
                    description: 'Optimum Education ERP is a full-fledged e-ERP (Electronic Enterprise Resource Planning – Browser Based) solution that covers full operations cycle of services & contracting businesses including fully integrated modules for services & contracting, procurement and inventory management.',
                    img: 'https://cdn2.mallats.com/AmthalGroup/img/premise/5.jpg', 
                    link: 'https://www.erpoptimum.com/en/Optimum/Education',       
                },
                {
                    id: 21,
                    title: localStorage.getItem('language') == 'ar' ? 'نظام شامل لإدارة المطاعم' : 'Food & Beverages (F&B)',
                    description: 'Optimum F&B ERP Software is a full-fledged e-ERP (Electronic Enterprise Resource Planning – Browser Based) solution that covers full operations cycle of services & contracting businesses including fully integrated modules for services & contracting, procurement and inventory management.',
                    img: 'https://cdn2.mallats.com/AmthalGroup/img/premise/6.jpg',
                    link: 'https://www.erpoptimum.com/en/Optimum/FoodandBeverages',
                    pdf: 'https://www.erpoptimum.com/docs/OptTrading.pdf',    
                },
                {
                    id: 22,
                    title: localStorage.getItem('language') == 'ar' ? 'نظام الموارد البشرية' : 'HRMS',
                    description: 'Optimum Human Resource & Payroll Management System (HRMS) covers all aspects of Human Resources required by any organization. It is parameterized to accommodate labor and social insurance laws of any country. The company structure and specific policies and procedures can be easily mapped in the system.',
                    img: 'https://cdn2.mallats.com/AmthalGroup/img/premise/7.jpg',
                    link: 'https://www.erpoptimum.com/en/Optimum/HRMS',
                    pdf: 'https://www.erpoptimum.com/docs/HRMS.pdf',
                    youtube: 'https://www.youtube.com/watch?v=tYqqVbMGoRo',
                    website: ''     
                },
                {
                    id: 23,
                    title: localStorage.getItem('language') == 'ar' ? 'نظام الحضور والإنصراف' : 'Time & Attendance',
                    description: 'Optimum Time & Attendance covers access control, roster and workforce management, project time sheet and attendance. It can manage all type of shifts and rotations with the functionalities of workforce management and time sheet for project management companies.',
                    img: 'https://cdn2.mallats.com/AmthalGroup/img/premise/8.jpg',
                    link: 'https://www.erpoptimum.com/en/Optimum/TA',
                    pdf: 'https://www.erpoptimum.com/docs/TimeAttendance.pdf',
                    youtube: 'https://www.youtube.com/watch?v=4k801RrDi6Q',
                    website: ''    
                },
                {
                    id: 24,
                    title: localStorage.getItem('language') == 'ar' ? 'نظام الأصول الثابتة' : 'Fixed Assets',
                    description: "Optimum Fixed Assets module records all the fixed assets bought or capitalized, whether tangible or intangible. It manages assets related documents, asset's tracking, and assets maintenance. It keeps track of all assets movements.",
                    img: 'https://cdn2.mallats.com/AmthalGroup/img/premise/9.jpg',
                    link: 'https://www.erpoptimum.com/en/Optimum/FixedAssets',
                    pdf: 'https://www.erpoptimum.com/docs/FixedAssets.pdf',
                    youtube: 'https://www.youtube.com/watch?v=tB9XydVx4Lk',
                    website: ''    
                },
                {
                    id: 25,
                    title: localStorage.getItem('language') == 'ar' ? 'نظام المشتريات والمدفوعات' : 'Procurement & Expenses',
                    description: 'Optimum Procurement & Expenses covers the full procurement cycle, from departmental / purchase request, quotation request, purchase orders, deliveries and invoices to payments. Systems is fully integrated with Inventory and Fixed Assets Modules.',
                    img: 'https://cdn2.mallats.com/AmthalGroup/img/premise/10.jpg',
                    link: 'https://www.erpoptimum.com/en/Optimum/Purchase',
                    pdf: 'https://www.erpoptimum.com/docs/Procurement.pdf',
                    youtube: 'https://www.youtube.com/watch?v=h6YKXz7E3zs',
                    website: ''    
                },
                {
                    id: 26,
                    title: localStorage.getItem('language') == 'ar' ?  'نظام البيع بالجملة والتجزئة' : 'Wholesale & Retail',
                    description: 'Optimum Wholesale & Retail management System covers the full sales cycle, from quotation, orders, deliveries and invoices to payments. Its unique Portal for salesmen is used to take customer requests and then convert these requests to quotations, orders or cash invoices.',
                    img: 'https://cdn2.mallats.com/AmthalGroup/img/premise/11.jpg',
                    link: 'https://www.erpoptimum.com/en/Optimum/Sales',
                    pdf: 'https://www.erpoptimum.com/docs/Wholesale.pdf',
                    youtube: '',
                    website: ''    
                },
                {
                    id: 27,
                    title: localStorage.getItem('language') == 'ar' ? 'نظام الحسابات (الأستاذ العام)' : 'Accounting System',
                    description: 'Optimum General Ledger is a Fully-fledged Activity-Based Cost Accounting (ABC) System that has unlimited segments of Chart of Accounts, Profit Centers, Customer Defined Analysis Code and Cost Centers. It is a Multi-company, Multi-branch and Multi-currency system. It complies with all the International Accounting Standards (IAS) in addition to Financial Reporting Standards (IFRS).',
                    img: 'https://cdn2.mallats.com/AmthalGroup/img/premise/12.jpg',
                    link: 'https://www.erpoptimum.com/en/Optimum/Ledger',
                    pdf: 'https://www.erpoptimum.com/docs/Financial.pdf',
                    youtube: '',
                    website: ''      
                },
                {
                    id: 28,
                    title: localStorage.getItem('language') == 'ar' ? 'نظام إدارة المخزون' : 'Inventory Management',
                    description: 'Optimum Inventory Management module controls the organizations inventory, whether it is for internal use or for resale. It maintains a fully-fledged inventory profile containing all aspects such as Product Information/Specifications, Re-Order level and quantity, Lead time, etc.',
                    img: 'https://cdn2.mallats.com/AmthalGroup/img/premise/13.jpg',
                    link: 'https://www.erpoptimum.com/en/Optimum/Inventory',
                    pdf: 'https://www.erpoptimum.com/docs/Inventory.pdf',
                    youtube: '',
                    website: ''     
                },
                {
                    id: 29,
                    title: localStorage.getItem('language') == 'ar' ? 'نظام إدارة علاقات العملاء' : 'CRM',
                    description: 'Optimum Customer Relationship Management (CRM) covers the complete cycle of sales module from creation of Customer Account, Contact, Lead & Opportunity Follow-up, Quotations and Order to Invoice Generation.',
                    img: 'https://cdn2.mallats.com/AmthalGroup/img/premise/14.jpg',
                    link: 'https://www.erpoptimum.com/en/Optimum/CRM',
                    pdf: 'https://www.erpoptimum.com/docs/CRM.pdf',
                    youtube: '',
                    website: ''    
                },
                {
                    id: 30,
                    title: localStorage.getItem('language') == 'ar' ? 'نظام إدارة علاقات الموردين' :'VRM',
                    description: "Optimum Vendor Relationship Management (VRM) provides a systematic, enterprise-wide assessment of suppliers’ capabilities in view of the organizations overall business strategy, a determination of what activities to engage in with different suppliers, and planning and execution of all interactions with suppliers, in a coordinated fashion across the relationship life cycle, to maximize the value realized through those interactions.",
                    img: 'https://cdn2.mallats.com/AmthalGroup/img/premise/15.jpg',
                    link: 'https://www.erpoptimum.com/en/Optimum/VRM',
                    pdf: '',
                    youtube: '',
                    website: ''     
                }]
            },
            {
                id: '4',
                title: 'FinTech Applications',
                products: [{
                    id: 31,
                    title: localStorage.getItem('language') == 'ar' ? 'نظام شامل للعمليات البنكية الرقمية' : 'Digital Core Banking',
                    description: 'Optimum Digital Core Banking is a full-fledged E-ERP (Electronic Enterprise Resource Planning - Total browser base) system that covers all back office operations of any bank or financial institution. It has the capability to integrate with any core banking solution for posting transactions.',
                    img: 'https://cdn2.mallats.com/AmthalGroup/img/fintech/1.jpg',
                    link: 'https://fintech.erpoptimum.com/en/OptimumFintech/DigitalCore',
                    pdf: '',
                    youtube: '',
                    website: ''    
                },
                {
                    id: 32,
                    title: localStorage.getItem('language') == 'ar' ? 'نظام شامل للعمليات الغير البنكية' : 'Bank Admin',
                    description: 'Optimum Core Banking Admin ERP is a full-fledged E-ERP (Electronic Enterprise Resource Planning - Total browser base) system that covers all back office operations of any bank or financial institution. It has the capability to integrate with any core banking solution for posting transactions.',
                    img: 'https://cdn2.mallats.com/AmthalGroup/img/fintech/2.jpg',
                    link: 'https://fintech.erpoptimum.com/en/OptimumFintech/BankAdmin',
                    pdf: 'https://fintech.erpoptimum.com/docs/BankAdmin.pdf',
                    youtube: 'https://www.youtube.com/watch?v=TBzu_Qh3czk',
                    website: ''    
                },
                {
                    id: 33,
                    title:  localStorage.getItem('language') == 'ar' ? 'نظام شامل لمؤسسات الصيرفة' : 'Money Exchange & Transfers',
                    description: 'Optimum Currency Exchange & Transfer Management software is a full-fledged E-ERP (Electronic Enterprise Resource Planning - Total browser base) system that covers all aspects of money exchange or transfer related companies. It is a multi-company, multi-branch and multi-location system.',
                    img: 'https://cdn2.mallats.com/AmthalGroup/img/fintech/3.jpg',
                    link: 'https://fintech.erpoptimum.com/en/OptimumFintech/MoneyExchange',
                    pdf: 'https://fintech.erpoptimum.com/docs/MoneyExchangeTransfer.pdf',
                    youtube: 'https://www.youtube.com/watch?v=PHn0QBZOjrQ',
                    website: ''      
                },
                {
                    id: 34,
                    title: localStorage.getItem('language') == 'ar' ? 'نظام شامل للمؤسسات المتناهية الصغر' : 'Financing Management ERP',
                    description: 'Optimum Financing Management is a full-fledged E-ERP (Electronic Enterprise Resource Planning - Total browser base) system that covers all operations of micro-finance banks and other institutions. It is a multi-company, multi-branch and multi-location system. It is capable of handling complete loan management at micro level.',
                    img: 'https://cdn2.mallats.com/AmthalGroup/img/fintech/4.jpg',
                    link: 'https://fintech.erpoptimum.com/en/OptimumFintech/FinancialManagement',
                    pdf: '',
                    youtube: '',
                    website: ''          
                },
                {
                    id: 35,
                    title: localStorage.getItem('language') == 'ar' ? 'نظام حماية الأجور' : 'Wages Protection System (WPS)',
                    description: 'Optimum Wages Protection System enables organizations to submit their employees’ salaries and wages through authorized banks and financial institutions. Organizations can onboard WPS compliant entities, submit salaries and disburse funds to employees through bank accounts, e-wallets or on counter pay out.',
                    img: 'https://cdn2.mallats.com/AmthalGroup/img/fintech/5.jpg',
                    link: 'https://fintech.erpoptimum.com/en/OptimumFintech/WagesProtection',
                    pdf: '',
                    youtube: '',
                    website: ''      
                },
                {
                    id: 36,
                    title: localStorage.getItem('language') == 'ar' ? 'نظام تسوية مدفوعات التجار' : 'Merchant Payment System (MPS)',
                    description: 'Merchant Payment System (MPS) helps your financial institution thrive. It helps you build merchant relationships stronger and efficient. It is capable of settling all type of merchant payments by integrating with core processing systems and the payment gateway at the same time.',
                    img: 'https://cdn2.mallats.com/AmthalGroup/img/fintech/6.jpg',
                    link: 'https://fintech.erpoptimum.com/en/OptimumFintech/MerchantPayment',
                    pdf: '',
                    youtube: '',
                    website: ''      
                },
                {
                    id: 37,
                    title: localStorage.getItem('language') == 'ar' ? 'نظام التسويات بين الشبكات' : 'Switch Reconciliation System',
                    description: 'Optimum Switch Reconciliation System is the perfect tool for reconciling transactions coming from third party systems be it cards management systems or switch transactions.',
                    img: 'https://cdn2.mallats.com/AmthalGroup/img/fintech/7.jpg',
                    link: 'https://fintech.erpoptimum.com/en/OptimumFintech/SwitchReconciliation',
                    pdf: '',
                    youtube: '',
                    website: ''      
                },
                {
                    id: 38,
                    title: localStorage.getItem('language') == 'ar' ? 'نظام الصيرفة' : 'Money Exchange',
                    description: 'Optimum Money Exchange module is an integrated system which covers the entire cycle of money exchange process from sale and purchase of currency to the money exchange and then updating the currency stock by store and cashier in-charge.',
                    img: 'https://cdn2.mallats.com/AmthalGroup/img/fintech/8.jpg',
                    link: 'https://fintech.erpoptimum.com/en/OptimumFintech/MoneyExchangeProd',
                    pdf: '',
                    youtube: '',
                    website: ''      
                },
                {
                    id: 39,
                    title: localStorage.getItem('language') == 'ar' ? 'نظام التمويل المالي المتناهي الصغر' : 'Loans Management',
                    description: 'Optimum Loans Management Module is an integrated web-based system for management of small loans by micro finance banks. It supports the cloud-based platform where the data would be hosted on cloud servers and then the system can be used over the internet.',
                    img: 'https://cdn2.mallats.com/AmthalGroup/img/fintech/10.jpg',
                    link: 'https://fintech.erpoptimum.com/en/OptimumFintech/LoansManagement',
                    pdf: '',
                    youtube: '',
                    website: ''      
                }]
            },
            {
                id: '5',
                title: 'Digital Marketing',
                products: [{
                    id: 40,
                    title: localStorage.getItem('language') == 'ar' ? 'عبر وسائل التواصل الاجتماعي' : 'Social Media',
                    description: "Social media marketing is a powerful way for businesses of all sizes to reach prospects and customers. Your customers are already interacting with brands through social media, and if you're not speaking directly to your audience through social platforms like Facebook, Twitter, Instagram, and Pinterest, you're missing out! Great marketing on social media can bring remarkable success to your business, creating devoted brand advocates and even driving leads and sales.",
                    img: 'https://cdn2.mallats.com/AmthalGroup/new/SOCIAL-MEDIA.jpg',
                    link: '/social-media',
                    pdf: '',
                    youtube: '',
                    website: '' 
                },
                {
                    id: 41,
                    title: localStorage.getItem('language') == 'ar' ? 'تحسين محرك البحث' : 'Search Engine Optimization (SEO)',
                    description: "With Al-Amthal's SEO, get more traffic to your website with versatile solutions for every step of your SEO journey..",
                    img: 'https://cdn2.mallats.com/AmthalGroup/new/SEO.jpg',
                    link: '/seo',
                    pdf: '',
                    youtube: '',
                    website: '' 
                },
                {
                    id: 42,
                    title: localStorage.getItem('language') == 'ar' ? 'الحملات الرقمية' : 'Digital Campaigns (Email / SMS / Push Notifications) (Email/SMS/Push)',
                    description: "With Al-Amthal, runing digital campaigns to promote your company, products or services are easier than ever. All you have to do is subscribe to the most suitable package for your business on any of Al-Amthal's apps like Mallats or Aqaraats and leave the rest to us. Our expert team will design and compose the messages of your choice.",
                    img: 'https://cdn2.mallats.com/AmthalGroup/new/ONLINE-MARKETING.jpg',
                    link: '/online-marketing',
                    pdf: '',
                    youtube: '',
                    website: '' 
                }]
            }];
            
            this.apiLoaded = this.http.jsonp('https://maps.googleapis.com/maps/api/js?key=AIzaSyC66wNmG9uVVFq2pfnNanWnuPLrrpuyePg', 'callback')
                .pipe(
                    map(() => true),
                    catchError(() => of(false)),
                );

            this.http.get('https://system.al-amthal.com/optimumapiv3/Noticeboard/CMSData?menuID=61&pageID=70&compID=16').subscribe({
                next: (val: any) => {
                    this.newsData = val.splice(0, 3);
                }
            });
        }
    }

    apiLoaded: Observable<boolean>;

    center: google.maps.LatLngLiteral = {lat: 26.229497016, lng: 50.546485817};
    zoom = 19;
    markerOptions: google.maps.MarkerOptions = {draggable: false};
    markerPositions: google.maps.LatLngLiteral[] = [
        {lat: 26.229497016, lng: 50.546485817}
    ];

    newsData: any = null;

    homeSlides: OwlOptions = null;
    teamSlides: OwlOptions = null;
    clientsSlider: OwlOptions = null;

    clientWrap: OwlOptions = null;
    divisions: any = [];
    productTabs: any = [
        
    ];
    

    // Tabs
    currentTab = '1';
    switchTab(event: MouseEvent, tab: string) {
        event.preventDefault();
        this.currentTab = tab;

        this.Location.go('#products/' + tab);
        this.tracker.send(window.location.href);
    }

	// Video Popup
	isOpen = false;
    openPopup(): void {
        this.isOpen = true;
    }
    closePopup(): void {
        this.isOpen = false;
    }

    openLink(link: string) {
        if(link.includes('://')) {
            window.open(link, '_blank');
        } else {
            this.router.navigate([link]);
        }
    }

    openImage(imageTitle: string, link: string) {
        let modalRef = this.modalService.open(ImageModalComponent,{windowClass: 'image-modal', centered: true, backdrop:true, size: 'md'});
        modalRef.componentInstance.ref = {
            title: imageTitle,
            image: link
        };
    }

    /* openPDF(pdfTitle: string, link: string) {
        let modalRef = this.modalService.open(PdfViewerModalComponent,{windowClass: 'image-modal', centered: true, backdrop:true, size: 'md'});
        modalRef.componentInstance.ref = {
            title: pdfTitle,
            link: link
        };
    } */

    clickPartner(type: string, title: string, link: string) {
        switch(type) {
            case 'pdf':
                this.openPDF(title, link);
                break;
            
            case 'image':
                this.openImage(title, link);
                break;
        }
    }

    openPDF(pdfTitle: string, link: string) {
        /* let offcanvasRef = this.offcanvasModal.open(PdfViewerOptionsModalComponent, { position: 'bottom', panelClass: 'offcanvas-fullscreen' });
        offcanvasRef.componentInstance.ref = {
            title: pdfTitle,
            link: link
        }; */
    }

    private isWithInDate(_startDate: string, _endDate: string): boolean {
        const currentDate = new Date();
        const startDate = new Date(_startDate);
        const endDate = new Date(_endDate);

        return currentDate >= startDate && currentDate <= endDate;
    }

    public getBackgroundImageUrl(newsValue: string): string {
        const encodedValue = this.textService.encode(newsValue).replace(/\(/g, '\\(').replace(/\)/g, '\\)');
        return `url(https://system.al-amthal.com/amthalv12/${encodedValue})`;
    }
}