import { Component, OnInit, HostListener,  ElementRef } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { TrackerService } from 'src/app/services/tracker.service';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})

export class NavbarComponent implements OnInit {
    public isSticky: boolean = false;
    public currentSection = 'home';
    public location: any;
    public navbarClass: any;
    public classApplied = false;

    toggleClass() {
        this.classApplied = !this.classApplied;
    }

    constructor(
        private router: Router,
        public Location: Location,
        private _el: ElementRef,
        private translate: TranslateService,
        private tracker: TrackerService ) {

        this.router.events.subscribe((event:any) => {
            let url  = this.router.url.split('/');

            if(url[1]) {
                this.currentSection = url[1];
            }

            if (event instanceof NavigationEnd ) { 
                this.location = this.router.url;
                if (this.location == '/home-three'){
                    this.navbarClass = 'navbar-area three';
                } else {
                    this.navbarClass = 'navbar-area';
                }
            }      
        });
    }
    @HostListener('window:scroll', ['$event'])
    checkScroll() {
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        if (scrollPosition >= 50) {
            this.isSticky = true;
        } else {
            this.isSticky = false;
        }
        
        let currentSection: string;
        const children = this._el.nativeElement.nextSibling.nextSibling.children;
        for (let i = 0; i < children.length; i++) {
            const element = children[i]; 
            if ('SECTION' == element.tagName) {
            const rect = element.getBoundingClientRect();
                if (rect.top <= 220 && rect.bottom >= 50) {
                    currentSection = element.id;
                }

            }
        } 
        if (currentSection && currentSection != this.currentSection ) {
            let currentUrl = this.location.split('/');
            let newUrl = currentUrl[0] + '/#'+currentSection;
            this.Location.go(newUrl);
            this.currentSection = currentSection;

            this.tracker.send(window.location.href);
        }
    }
    removeActiveClass() {
        this.currentSection = '';

    }

    ngOnInit(): void {}

    useLanguage(language: string) {
        /* this.translate.use(language);

        if(language == 'ar') {
            document.documentElement.dir = "rtl";
        } else {
            document.documentElement.dir = "ltr";
        }

        document.documentElement.lang = language; */

        localStorage.setItem('language', language);

        window.location.reload();
    }

}