import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Input } from '@angular/core';
import { NgbActiveModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-otp-modal',
  templateUrl: './otp-modal.component.html',
  styleUrls: ['./otp-modal.component.scss']
})
export class OtpModalComponent {
  @Input() ref: any;
  public isSubmitted: boolean = false;
  public isFailed: boolean = false;
  public responseMessage: string = null;
  public otpEntered: string = null;
  public isLoading: boolean = false;
  constructor(
    public http: HttpClient,
    public activeModal: NgbActiveModal,
    private modalConfig: NgbModalConfig,
  ) {}


  closePopUp(isTrue) {
    this.activeModal.close(isTrue);
    
  }
  submit() {
    
    this.isSubmitted = true;
    this.isFailed = false;
    this.responseMessage = '';
    if(this.otpEntered == null || this.otpEntered == '') {
      this.isFailed = true;
      this.responseMessage = 'Please enter OTP code';
      return;
    }
    this.isLoading = true;
    let payload: any = {
      otp: this.otpEntered,
      reference: this.ref.toString()
    }
    this.http.post<any>('https://system.al-amthal.com/optimumapiv3/Website/Request/ContactusOtp',payload).subscribe({
      next: (val: any) => {
        this.isLoading = false;
        if(val.succeeded) {
          this.isFailed = false;
          

          this.closePopUp(true);
        } else {
          this.isFailed = true;
          this.responseMessage = val.messages[0];
        }
      }
    });
    
  }

  
}
