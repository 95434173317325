import { Component } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-social-media',
  templateUrl: './social-media.component.html',
  styleUrls: ['./social-media.component.scss']
})
export class SocialMediaComponent {
  constructor(private meta: Meta,
    private _translate: TranslateService,
    private title: Title) {
    this.meta.updateTag({name: 'description', content: 'Amthal Group is the leading cloud ERP solutions provider in the Kingdom of Bahrain and other GCC countries.'});


    this._translate.get('Amthal Group - Leading Business Solutions Provider in Middle East').subscribe({
      next: (res: string) => {
        this.title.setTitle(res);
      }
    });
  }
}
