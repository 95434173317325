import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { TextService } from 'src/app/services/text.service';

@Component({
    selector: 'app-about',
    templateUrl: './about.component.html',
    styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

    constructor(private meta: Meta,
		private _translate: TranslateService,
		private title: Title,
		public textService: TextService) {
		this._translate.get('Experience Al Amthal Group and uncover our team, expertise, and commitment to exceptional software products & services.').subscribe({
            next: (res: string) => {
				this.meta.updateTag({name: 'description', content: res});
            }
        });

		this._translate.get('About Us').subscribe({
            next: (res: string) => {
              this.title.setTitle(res);
            }
        });
	}

    ngOnInit(): void {}
    
    clientWrap: OwlOptions = {
		loop:true,
		margin:30,
		nav:false,
		mouseDrag: true,
		items:1,
		dots: false,
		autoHeight: true,
		autoplay: true,
		smartSpeed: 800,
		autoplayHoverPause: true,
		center: false,
		responsive:{
			0:{
				items:1,
				margin: 10,
			},
			576:{
				items:1,
			},
			768:{
				items:2,
				margin: 20,
			},
			992:{
				items:2,
			},
			1200:{
				items:2,
			}
		}
    }

	// Video Popup
	isOpen = false;
    openPopup(): void {
        this.isOpen = true;
    }
    closePopup(): void {
        this.isOpen = false;
    }

}