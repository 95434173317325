import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  constructor() { }

  public DontShowAnnoucement(id: number): void {
    const data = this.getItem('announcements');
    let idArray: number[] = [];
    
    if (data) {
      if (!data.includes(id)) {
        idArray = [...data, id]; // Use spread operator to create a new array with the updated values
        this.setItem('announcements', idArray);
      }
    } else {
      idArray.push(id);
      this.setItem('announcements', idArray);
    }
  }
  
  public ShowAnnoucement(id: number): void {
    const data = this.getItem('announcements');  
    if (data && data.includes(id)) {
      const updatedArray = data.filter(itemId => itemId !== id);
      this.setItem('announcements', updatedArray);
    }
  }
  
  public IsViewPopUp(id: number): boolean {
    const data = this.getItem('announcements');  
    if (data && data.includes(id)) {
      return false;
    } else {
      return true;
    }
  }

  private setItem(key: string, value: any): void {
    sessionStorage.setItem(key, JSON.stringify(value));
  }

  private getItem(key: string): any {
    const storedItem = sessionStorage.getItem(key);
    return storedItem ? JSON.parse(storedItem) : null;
  }
}
