import { HTTP_INTERCEPTORS, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor() { }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<Object>> {
    // Check if the request is for the Google Maps API
    if (this.isGoogleMapsApiRequest(req.url)) {
      // If it is, skip adding the LanguageID header
      return next.handle(req);
    }

    const languageID = localStorage.getItem('language') == 'en' || !localStorage.getItem('language') ? '1' : '2';

    if (languageID) {
      req = req.clone({ headers: req.headers.set('LanguageID', languageID) });
    }

    return next.handle(req);
  }

  private isGoogleMapsApiRequest(url: string): boolean {
    // Check if the URL is for the Google Maps API
    return url.includes('maps.googleapis.com/maps/api/js');
  }
}

export const authInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
];