<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>404 Error</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li class="active">404 Error</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img loading="lazy" src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img loading="lazy" src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img loading="lazy" src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img loading="lazy" src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img loading="lazy" src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<div class="error-area ptb-100">
    <div class="error-page">
        <img loading="lazy" src="assets/images/404.gif" alt="error">
        <h3>Oops! Page Not Found</h3>
        <p>The page you were looking for could not be found.</p>
        <a routerLink="/" class="box-btn">Return To Home Page</a>
    </div>
</div>