<section>
    <div class="page-title-area">
        <div class="container">
            <div class="page-title-content">
                <h2>{{'Contact Us' | translate}}</h2>
                <ul>
                    <li><a routerLink="/">{{'Home' | translate}} </a></li>
                    <li class="active">{{'Contact Us' | translate}}</li>
                </ul>
            </div>
        </div>
    
        <div class="page-shape">
            <div class="shape1"><img loading="lazy" src="assets/images/shape/1.png" alt="shape" /></div>
            <div class="shape3"><img loading="lazy" src="assets/images/shape/3.png" alt="shape" /></div>
            <div class="shape4"><img loading="lazy" src="assets/images/shape/4.png" alt="shape" /></div>
            <div class="shape5"><img loading="lazy" src="assets/images/shape/5.png" alt="shape" /></div>
            <div class="shape6"><img loading="lazy" src="assets/images/shape/6.png" alt="shape" /></div>
        </div>
    </div>
    
    <section class="contact-area pt-100 pb-70">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-sm-6">
                    <div class="single-contact">
                        <div class="icon">
                            <i class='bx bx-envelope'></i>
                        </div>
    
                        <div class="content">
                            <h3>{{'Email' | translate}}</h3>
                            <p>amthaladmin&#64;al-amthal.com</p>
                        </div>
                    </div>
                </div>
    
                <div class="col-lg-4 col-sm-6">
                    <div class="single-contact">
                        <div class="icon">
                            <i class='bx bx-location-plus'></i>
                        </div>
    
                        <div class="content">
                            <h3>{{'Address' | translate}}</h3>
                            <p>{{ 'P.O. Box 2949, Manama, Bahrain' | translate }}<br />{{ 'Exhibition Tower, 11th Floor, Building 614, Road 1011, Block 410, Sanabis, Kingdom of Bahrain' | translate }}</p>
                        </div>
                    </div>
                </div>
    
                <div class="col-lg-4 col-sm-12">
                    <div class="single-contact">
                        <div class="icon">
                            <i class='bx bx-phone-call'></i>
                        </div>
    
                        <div class="content">
                            <h3>{{'Phone' | translate}}</h3>
                            <p class="mb-0 d-flex align-items-center ps-2 pe-2 border-bottom pb-2">
                                <img loading="lazy" class="me-1" src="https://cdn2.mallats.com/AmthalGroup/img/bahrain.png" width="20px">
                                <b class="me-1 w-100">{{'Bahrain' | translate}}</b>
                                <span class="text-end w-100 phone-number">
                                    <span>+973 17 297 227</span>
                                </span>
                            </p>
                            <p class="mb-0 d-flex align-items-center ps-2 pe-2 border-bottom pb-2 pt-2">
                                <img loading="lazy" class="me-1" src="https://cdn2.mallats.com/AmthalGroup/img/oman.png" width="20px">
                                <b class="me-1 w-100">{{'Oman' |  translate}}</b>
                                <span class="text-end w-100 phone-number">
                                    <span>+968 94 102 900</span>
                                </span>
                            </p>
                            <p class="mb-0 d-flex align-items-center ps-2 pe-2 border-bottom pb-2 pt-2">
                                <img loading="lazy" class="me-1" src="https://cdn2.mallats.com/AmthalGroup/img/qatar.png" width="20px">
                                <b class="me-1 w-100">{{'Qatar' | translate}}</b>
                                <span class="text-end w-100 phone-number">
                                    <span>+974 4462 1849</span>
                                    <br />
                                    <span>+974 3336 5538</span>
                                </span>
                            </p>
                            <p class="mb-0 d-flex align-items-center ps-2 pe-2 pt-2">
                                <img loading="lazy" class="me-1" src="https://cdn2.mallats.com/AmthalGroup/img/saudi-arabia.png" width="20px">
                                <b class="me-1 w-100">{{'Saudi Arabia' | translate}}</b>
                                <span class="text-end w-100 phone-number">
                                    <span>+966 56 808 2779</span>
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
    <section class="home-contact-area home-2-contact pb-100">
        <div class="container">
            <div class="section-title">
                <span>{{'Contact Us' | translate}}</span>
                <h2>{{'Reach Out and Connect' | translate}}</h2>
            </div>
            <div class="row">
                <div class="col-lg-6 col-md-6">
                    <div class="content">
                        <form [formGroup]="contactForm" (ngSubmit)="onSubmit()" novalidate>
                            <div class="row">
                                <div class="col-lg-12 col-sm-12">
                                    <div *ngIf="getFormValidator('title')" class="text-error">
                                        <span *ngIf="contactForm.get('title').errors.required">{{'Title required' | translate }}</span>
                                    </div>
                                    <div class="form-group">
                                        <input 
                                            type="title" 
                                            name="title" 
                                            id="title" 
                                            class="form-control"
                                            [placeholder]="'Your Title' | translate"
                                            required
                                            [ngClass]="{'border-error' : getFormValidator('title') && contactForm.get('title').errors.required}"
                                            formControlName="title" 
                                            />
                                    </div>
                                </div>
                                
                                <div class="col-lg-12 col-sm-12">
                                    <div class="form-group">
                                        <div *ngIf="getFormValidator('name')" class="text-error">
                                            <span *ngIf="contactForm.get('name').errors.required">{{'Name required' | translate}}</span>
                                        </div>
                                        <input 
                                            type="text" 
                                            name="name" 
                                            id="name" 
                                            class="form-control" 
                                            [ngClass]="{'border-error' : getFormValidator('name') && contactForm.get('name').errors.required }"
                                            required 
                                            [placeholder]="'Your Name' | translate" 
                                            formControlName="name" 
                                        />                                    
                                    </div>
                                </div>
    
                                <div class="col-lg-12 col-sm-12">
                                    <div class="form-group">
                                        <div *ngIf="getFormValidator('email')" class="text-error">
                                            <span *ngIf="contactForm.get('email').errors.required">{{'Email required' | translate}}</span>
                                        </div>
                                        <input 
                                            type="email" 
                                            name="email" 
                                            id="email" 
                                            class="form-control" 
                                            required 
                                            [placeholder]="'Your Email' | translate" 
                                            [ngClass]="{'border-error' : getFormValidator('email') && contactForm.get('email').errors.required}"
                                            formControlName="email"                                        
                                            />
                                    </div>
                                </div>
    
                                
    
                                <div class="col-lg-12 col-sm-12">
                                    <div *ngIf="getFormValidator('phone_number')" class="text-error">
                                        <span *ngIf="contactForm.get('phone_number').errors.required">{{'Phone No. required' | translate}}</span>
                                    </div>
                                    <div class="form-group">
                                        <input
                                            type="text" 
                                            class="form-control" 
                                            required 
                                            [ngClass]="{'border-err' : getFormValidator('phone_number') && contactForm.get('phone_number').errors.required}"                               
                                            name="phone_number"
                                            formControlName="phone_number"
                                            placeholder="Phone Number"
                                        />
                                    </div>
                                </div>
    
                                <div class="col-lg-12 col-sm-12">
                                    <div *ngIf="getFormValidator('comp_name')" class="text-error">
                                        <span *ngIf="contactForm.get('comp_name').errors.required">{{'Company required' | translate}}</span>
                                    </div>
                                    <div class="form-group">
                                        <input 
                                            type="text" 
                                            name="comp_name" 
                                            id="comp_name" 
                                            class="form-control" 
                                            required 
                                            [placeholder]="'Company Name' | translate"
                                            [ngClass]="{'border-error' : getFormValidator('comp_name') && contactForm.get('comp_name').errors.required}"
                                            formControlName="comp_name"
                                            />
                                    </div>
                                </div>
    
                                <div class="col-lg-12 col-sm-12">                               
                                    <div class="form-group">
                                        <div *ngIf="getFormValidator('msg_subject')" class="text-error">
                                            <span *ngIf="contactForm.get('msg_subject').errors.required">{{'Subject required' | translate }}</span>
                                        </div>
                                        <input 
                                            type="text" 
                                            name="msg_subject" 
                                            id="msg_subject" 
                                            class="form-control" 
                                            required 
                                            [placeholder]="'Subject' | translate"
                                            [ngClass]="{'border-error' : getFormValidator('msg_subject') && contactForm.get('msg_subject').errors.required}"
                                            formControlName="msg_subject" 
                                        />
                                    </div>
                                </div>
    
                                <div class="col-lg-12 col-md-12">
                                    <div *ngIf="getFormValidator('message')" class="text-error">
                                        <span *ngIf="contactForm.get('message').errors.required">{{'Message required' | translate}}</span>
                                    </div>
                                    <div class="form-group">
                                        <textarea 
                                            name="message" 
                                            class="form-control" 
                                            id="message" 
                                            cols="30" 
                                            rows="5" 
                                            required 
                                            [placeholder]="'Your Message' | translate"
                                            [ngClass]="{'border-error' : getFormValidator('message') && contactForm.get('message').errors.required}"
                                            formControlName="message" 
                                        >
                                        </textarea>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 mb-2">
                                    <small *ngIf="lang == 'en'"  style="font-size: 0.75em;">
                                        This site is protected by reCAPTCHA and the Google
                                        <a href="https://policies.google.com/privacy" target="_blank">Privacy Policy</a> and
                                        <a href="https://policies.google.com/terms" target="_blank">Terms of Service</a> apply.
                                      </small>
                                      <small *ngIf="lang == 'ar'" >
                                        هذا الموقع محمي بواسطة reCAPTCHA و 
                                        <a href="https://policies.google.com/privacy" target="_blank">تطبق سياسة الخصوصية</a> و
                                        <a href="https://policies.google.com/terms" target="_blank">شروط الخدمة</a> من Google.
                                      </small>                                    
                                </div>
    
                                <div class="col-lg-12 col-md-12">
                                    <button type="submit" class="default-btn page-btn box-btn">
                                        <div class="loader"  *ngIf="isLoading"></div>
                                        <span *ngIf="!isLoading">{{'Submit' | translate}}</span>
                                        
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
    
                <div class="col-lg-6 col-md-6">
                    <!-- <div class="contact-img contact-img-2">
                        <img loading="lazy" src="assets/images/contact-img2.jpg" alt="contact" />
                    </div> -->
    
                    <div class="google-maps-container" *ngIf="apiLoaded | async">
                        <google-map
                            width="100%"
                            height="100%"
                            [center]="center"
                            [zoom]="zoom">
                            <map-marker *ngFor="let markerPosition of markerPositions"
                                [position]="markerPosition"
                                [options]="markerOptions"></map-marker>
                        </google-map>
                    </div>
                </div>
            </div>
        </div>
    </section>
</section>
