import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TrackerService {
  private lastLink: string = '';

  constructor(private http: HttpClient) { }

  send(link: string) {
    if(this.lastLink != link) {
      if(!link.includes('#')) {
        this.call(link);
      } else {
        if(this.lastLink.split('#')[1]?.split('/')[0] != link.split('#')[1]?.split('/')[0]) {
          this.call(link);
        } else if(this.lastLink.split('#')[1]?.split('/')[1] != link.split('#')[1]?.split('/')[1]
          && this.lastLink.split('#')[1]?.split('/')[0] == link.split('#')[1]?.split('/')[0]
          && link.split('#')[1]?.split('/')[1]) {
          this.call(link);
        }
      }
    }
  }

  call(link: string) {
    this.lastLink = link;

    this.http.post('https://system.al-amthal.com/optimumapiv3/Website/Request/VisitorsRecord', {
      link: this.lastLink
    }, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }).subscribe({
      next: (val: any) => {
        // console.log(`Received ${val}`);
      },
      error: err => {
        
      }
    });

    // console.log(this.lastLink);
  }
}
